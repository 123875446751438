<!-- 服务商-->
<template>

    <div class="bg-fff prohibit-copy" oncontextmenu="return false;" style="height: 100%;">
        <nav-header fixed :title="detailInfo.title">
            <template #right>
                <fen-xiang>
                    <a class="tal-text-zhu-se tal-flex tal-justify-center tal-items-center tal-space-x-1"
                       href="javascript:">
                        <i class="iconfont icon-fen-xiang tal-text-zhu-se tal-font-bold"></i>
                        <span>分享</span>
                    </a>
                </fen-xiang>
            </template>
        </nav-header>
        <div class="body max-width" v-if="Object.keys(detailInfo).length>0">
            <div class="page-content">
                <div class="lun-bo">
                    <!--轮播-->
                    <div class="special-swiper">
                        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                            <van-swipe-item v-for="(item,index) in detailInfo.image" :key="index">
                                <van-image
                                        @click="showImage(index)"
                                        width="100%"
                                        :src="item.url"
                                />
                            </van-swipe-item>
                        </van-swipe>
                    </div>
                </div>
                <!--        <div class="lie-biao">-->
                <div class="lie-biao">
                    <!-- 内容数据-->
                    <div class="detail_title">
                        {{ detailInfo.title }}
                    </div>
                    <div class="case_produce"
                         @click="$router.push({name:'FuWuShangInfo',query:{g_id:detailInfo.g_id}})"
                         v-if="JSON.stringify(serviceInfo) !== '{}'"
                    >
                        <div class="case_produce_left">
                            <van-image
                                    round
                                    width="5rem"
                                    height="5rem"
                                    :src="serviceInfo.logo"
                            />
                            <div class="case_produce_text van-ellipsis">
                                <h4 class="van-ellipsis"
                                    style="font-size: 16px;margin-bottom: 5px;"
                                >
                                    {{ serviceInfo.g_name }}
                                </h4>
                                <h5 style="margin: 0;">
                                    共<span style="color: #4A4096;">{{ getFuWuZhiLiang(serviceInfo.joinMinGid) }}</span>服务，共
                                    <span
                                            style="color: #4A4096;">{{ anLiNumber }}</span>个案例
                                </h5>
                                <h5 style="font-size: 12px;color: #969799;margin-top: 5px;">
                                    {{ serviceInfo?.php_address?.name_string }} </h5>
                            </div>
                        </div>
                        <div class="case_produce_right">
                            <i class="iconfont icon-xiangxiazhanhang" style="font-size: 18px;color: #4a4096;"></i>
                        </div>
                    </div>
                    <div class="detail_content">
                        <div v-html="detailInfo.content" style="white-space: pre-line;"></div>
                    </div>
                </div>
            </div>

        </div>
        <contact-footer-nav/>
        <kuan-jie-tiao-zhuan @lol-click="tiaoZHuan"
                             :url="`/gzhphp/fu_wu_shang/getAnLiInfoKuaiJieTiaoZHuan?id=${$route.query.id}`"/>
    </div>
</template>


<script>
//服务大厅
import NavHeader from '@/components/top/NavHeader'
import contactFooterNav from "../open/contactFooterNav";
import http from "../../api/http";
import {ImagePreview} from 'vant';
import XEUtils from "xe-utils";
import {getChengJiaoEr, getFuWuZhiLiang} from "@/api/lol_cmf_gys";
import KuanJieTiaoZhuan from "@/components/btn/KuanJieTiaoZhuan";
import FenXiang from "@/components/fenXiang.vue";
import QiYeWeiXi from "@/api/QiYeWeiXi";
import {get} from "lodash";

export default {
    name: 'FuWuShangInfoDatail',
    components: {
        FenXiang,
        KuanJieTiaoZhuan,
        NavHeader,
        contactFooterNav
    },
    data() {
        return {
            id: this.$route.query.id,
            anLiNumber: 0,
            title: '服务商主页',
            detailInfo: {}, //详情数据
            serviceInfo: {}  //服务商数据
        }
    },
    mounted() {
        this.getCaseDetail();
    },
    methods: {
        tiaoZHuan(item) {
            this.$router.replace({name: 'FuWuShangInfoDetail', query: {id: item.id}})
        },
        getFuWuZhiLiang,
        showImage(index) {
            /*let image = XEUtils.map(this.detailInfo.image, n => {
              return n.url
            })

            ImagePreview({
              images: image,
              startPosition: index,
            })*/

        },
        //案例详情
        getCaseDetail() {
            http.get('/gzhphp/fu_wu_shang/getAnLiInfo', {params: {id: this.id}}).then(res => {
                if (res.data) {
                    this.title = res.data.title;
                    this.detailInfo = res.data;
                    this.getServiceDetail();
                    this.getLoad()
                    this.setFenXiang()
                }
            })
        },
        setFenXiang() {
            QiYeWeiXi.fenXiang({
                title: this.title,
                desc: "赶快点击进来查看详情吧！",
                link: location.href,
                imgUrl: get(this.detailInfo, 'image.0.url', '')
            })
        },
        //服务商详情
        getServiceDetail() {
            http.get('/gzhphp/fu_wu_shang/getFuWuShangInfo', {
                params: {id: this.detailInfo.g_id},
                load_error: false
            }).then(res => {
                if (res.code === 1) {
                    this.serviceInfo = res.data;
                }
            })
        },
        // 获取数据
        getLoad() {
            http.get('/gzhphp/fu_wu_shang/getAnLi', {
                params: {g_id: this.detailInfo.g_id, pageSize: 1}
            }).then(res => {
                if (res.code === 1) {
                    this.anLiNumber = res.data.total
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
@import "../../assets/da-ting.less";

.body {
  background: white;
}

.service_title {
  border-left: 3px solid #4A4096;
  font-size: 16px;
  margin: 15px 0;
  padding-left: 5px;
  font-weight: 600;
}

.page-content {
  display: block;

  .special-swiper {

  }

  .dao-hang {
    height: auto;
  }

  .service_type_container {
  }
}

.lie-biao {

  background: white;
  padding: 15px;

  .detail_title {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .case_produce {
    border-radius: 4px;
    background-color: #ebf1fe;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    .case_produce_left {
      display: flex;
      align-items: center;
      overflow-x: hidden;

      .case_produce_text {
        margin-left: 10px;
        overflow: hidden;
      }
    }
  }

  .detail_content {
    padding: 15px 0;
  }
}

.service_type_container {
  margin: 15px;

  .van-col.van-col--8:nth-child(3n+1) {
    text-align: left;
  }

  .van-col.van-col--8:nth-child(3n+2) {
    text-align: center;
  }

  .van-col.van-col--8:nth-child(3n) {
    text-align: right;
  }
}

.select_button {
  width: 90%;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 5px;
  background-color: white;
  color: #4A4096;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  border: 1px solid #4A4096;
}

.my-swipe .van-swipe-item {
  color: #fff;
}

.btn_active {
  color: white;
  background-color: #4A4096;
  font-size: 13px;
}

.icon-xiangxiazhanhang {
  display: inline-block;
  font-size: 12px;
}

.icon-xiangxiazhanhang.more {
  transform: rotate(90deg);
}

.icon-xiangxiazhanhang.show_down {
  transform: rotate(-90deg);
}

.body {
  position: relative;
}

/* 浮动布局*/
.become-special-fixed {
  background-color: #ef9135;
  position: fixed;
  right: 0;
  top: 50%;
  padding: 5px;
  border-radius: 8px 0 0 8px;
  color: white;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.company-header {
  color: white;
  padding: 20px 15px 15px 15px;
  background-image: url("../../assets/images/serviceInfoBg.png");

  .company-produce {
    display: flex;

    .image-div {
      width: 65px !important;
      height: auto !important;
      border-radius: 6px !important;
      overflow: hidden;
    }

    .company-produce_right {
      margin-left: 10px;
    }
  }

  .service_type {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 10px;

    .service_type_list {
      text-align: center;

      h4 {
        margin-bottom: 5px;
      }

      h5 {
        margin-top: 0;
        opacity: .85;
      }
    }
  }

  .company_title {
    font-size: 16px;
    margin: 5px 0;
    margin-top: 0;
  }

  .company_position {
    margin: 5px 0;
    opacity: .75;
  }

  .enterprise_auth {
    border: 1px solid #ef9135;
    color: #ef9135;
    border-radius: 3px;
    font-size: 12px;
    display: inline-block;
    align-items: center;
    padding: 0 5px;

    i {
      font-size: 12px;
    }
  }
}

.zhu-tu-bg {

}
</style>
